<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card custom-card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h3><i class="fa fa-pen"></i> แก้ไขผู้ใช้งาน</h3>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-user :user="user" :groups="groups" :flagAdd="false" :funcs="funcs"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/user'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formUser from './form.user';
import DateUtils from '../../../util/dateUtils';
import StringUtils from '../../../util/StringUtils';
import * as scripts from '../../../util/scripts';
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  name: 'customer-view-page-customer-edit',
  data () {
    return {
      permission:{
        appSlug: 'user',
        actionData: {}
      },
      user: {},
      groups: [],
      funcs: scripts.funcs,
      id: '',
    }
  },
  methods: {
    async editHandler () {
      const file = this.user.file;
      delete this.user.file;
      this.user.dobData = DateUtils.dateFormat(this.user.dob, "YYYY-MM-DD");
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.putData(`/ab_users/${this.user.id}`, this.user);
      if(result&&result.status.code=="200"){
        if(file){
          // const params = new FormData();
          // params.append("fileUpload", file);
          // await this.HttpServices.postFormData(`/ab_users/upload/${this.user.id}`, params);
          const s3Auth = await this.HttpServices.authenS3();
          if(s3Auth._info){
            const accessToken = s3Auth._info.secret.accessToken;
            let params = new FormData();
            params.append("file", file);
            params.append("title", file.name);
            const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
            if(resultMedia.media&&resultMedia.media.length>0){
              const media = resultMedia.media[0];
              const mediaUpload = {
                id: media.id,
                original_name: media.original_name,
                mime_type: media.mime_type,
                size: media.size,
                url: media.url
              };

              params = new FormData();
              const thumbFile = await StringUtils.resizeBlobToFile({file: file, maxSize: 200});
              params.append("file", thumbFile);
              params.append("title", thumbFile.name);
              params.append("file_category", 'profile');
              let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
              if(resultThumb.media&&resultThumb.media.length>0){
                const mediaThumb = resultThumb.media[0];
                mediaUpload.id_t = mediaThumb.id;
                mediaUpload.original_name_t = mediaThumb.original_name;
                mediaUpload.mime_type_t = mediaThumb.mime_type;
                mediaUpload.size_t = mediaThumb.size;
                mediaUpload.url_t = mediaThumb.url;
              }
              params = {media: JSON.stringify(mediaUpload)};
              const resultUpload = await this.HttpServices.postData(`/ab_users/upload/${this.user.id}`, params);
              if(resultUpload&&resultUpload.status.code=="200"){
                const data = resultUpload.data;
                if(data&&data.older){
                  try{
                    const older = JSON.parse(data.older);
                    await this.HttpServices.deleteDataS3(older.id, accessToken);
                    if(older.id_t){
                      await this.HttpServices.deleteDataS3(older.id_t, accessToken);
                    }
                  }catch(e){}
                }
              }
            }
          }
        }
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, async ()=>{
          const profile = await SessionStorageUtils.decodeUser();
          if(profile.id==this.user.id){
            const refreshToken = await this.HttpServices.postData(`/auth/refreshToken`, {id: profile.id, username: profile.name});
            SessionStorageUtils.setUser(refreshToken.data);
            await SessionStorageUtils.setSession("reload", true);
            this.$router.go();
          }else{
            this.$router.push('/user');
          }
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler(){
      this.$router.push("/user");
    },

    async getUser(){
      const result = await this.HttpServices.getData(`/ab_users/${this.id}`);
      if(result&&result.status.code=="200"){
        this.user = result.data;
        if(this.user.dob){
          this.user.dob = new Date(this.user.dob);
        }
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล User`, ()=>{
          this.$router.push('/user');
        });
      }
    },

    async getGroups () {
      this.groups = await this.HttpServices.getMasterData("/master/getAbGroups");
    }
  },
  components: {
    formUser
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    const reload = await SessionStorageUtils.getSession("reload");
    if(reload){
      this.SessionStorageUtils.deleteSession("reload");
      this.$router.push('/user');
    }
    this.id = await this.SessionStorageUtils.getSession("page-user");
    await this.getGroups();
    await this.getUser();
  }
}
</script>
<style lang="">

</style>
