<template lang="">
  <div class="row">
    <div class="col-lg-6">
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">ชื่อผู้ใช้</label>
        <div class="col-sm-8">
          <base-input name="username"
                      :rules="{required: true, min: 6}"
                      placeholder="ชื่อผู้ใช้"
                      v-model="user.name" :disabled="!flagAdd">
          </base-input>
        </div>
      </div>
      <div class="mb-4 row" v-if="flagAdd">
        <label class="col-sm-4 col-form-label label_algin_right">รหัสผ่าน</label>
        <div class="col-sm-8">
          <base-input type="password" name="password"
                      :rules="{required: flagAdd, min: 8}"
                      placeholder="รหัสผ่าน"
                      v-model="user.password">
          </base-input>
        </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">ชื่อ-นามสกุล</label>
        <div class="col-sm-8">
          <base-input name="fullname"
                      :rules="{required: true}"
                      placeholder="ชื่อ-นามสกุล"
                      v-model="user.fullname">
          </base-input>
        </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">อีเมล</label>
        <div class="col-sm-8">
          <base-input name="email" type="email"
                      :rules="{required: true}"
                      placeholder="อีเมล"
                      v-model="user.email">
          </base-input>
        </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">เบอร์โทรศัพท์</label>
        <div class="col-sm-8">
          <base-input name="moblie" min="10" max="10" v-number-only
                      :rules="{required: true, min: 10, max: 10}"
                      placeholder="เบอร์โทรศัพท์"
                      v-model="user.mobile">
          </base-input>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">ไลน์</label>
        <div class="col-sm-8">
          <base-input name="line"
                      :rules="{required: true}"
                      placeholder="ไลน์"
                      v-model="user.line">
          </base-input>
        </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">วันเดือนปีเกิด</label>
        <div class="col-sm-8">
          <date-picker :format="'DD/MM/YYYY'" v-model="user.dob" class="w-100" input-class="form-control" placeholder="วันเดือนปีเกิด" :append-to-body="false" :popup-style="{left: 0}"></date-picker>
          <base-input name="dob" :rules="{required: true}" v-model="dob" class="custom-input-valid-datepicker"></base-input>
        </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">กลุ่มผู้ใช้</label>
        <div class="col-sm-8">
            <select class="form-control" v-model="user.group_id">
              <option v-for="(data, index) in groups" :key="`select_data_${index}`" :value="data.id">
                {{data.name}}
              </option>
            </select>
          </div>
      </div>
      <div class="mb-4 row">
        <label class="col-sm-4 col-form-label label_algin_right">ที่อยู่</label>
        <div class="col-sm-8">
          <b-form-textarea name="address" placeholder="ที่อยู่" v-model="user.address" rows="3" max-rows="5"></b-form-textarea>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mt-2 row">
        <label class="col-sm-4 col-form-label label_algin_right">รูปผู้ใช้งาน <br><span class="text-xs text-danger">(กดที่รูปภาพเพื่อเปลี่ยนรูป)</span></label>
        <div class="col-sm-8">
          <div>
            <input  type="file"
                    id="fileUpload"
                    accept="image/*"
                    style="display: none"
                    @change="uploadFileChangeHandler"
                    name="imgs[]"/>
            <img  :src="user.avatar_encode?`${user.avatar_encode}`:funcs.isImgEmpty(displayImg)"
                  class="img-thumbnail"
                  style="max-width: 180px;"
                  @click="uploadFileHandler()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import config from '../../../util/config';
export default {
  name: 'acctrl-form-user',
  data () {
    return {
      dob: "",
      displayImg: '',
      file: '',
      apiUrl: config.apiUrl
    }
  },
  props: [
    'user',
    'flagAdd',
    'groups',
    'funcs'
  ],
  methods: {
    async getGroups () {
      this.groups = await this.HttpServices.getMasterData("/master/getAbGroups");
    },
    uploadFileHandler(){
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler (event) {
      let img = event.target.files;
      if (img.length > 0) {
        const vm = this;
        const reader = new FileReader();
        reader.onload = function (e) {
          vm.user.avatar_encode = null;
          vm.displayImg = e.target.result;
        };
        this.user.file = img[0];
        reader.readAsDataURL(img[0]);

      } else {
        this.displayImg = null;
        this.file = null;
      }
    }
  },
  watch:{
    'user.dob'(newVal){
      if(newVal){
        this.dob = ""+newVal;
      }else{
        this.dob = "";
      }
    }
  }
}
</script>
<style scoped>
  input[type=file] {
    left: 0;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: 117px;
  }
</style>
